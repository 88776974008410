import React from 'react';
import { Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { login, useAuth, sendRequest } from '../utils';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [logged] = useAuth();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  if (logged) {
    return <Navigate to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    const opts = {
      email,
      password,
    };

    sendRequest('auth/login', 'POST', opts, false)
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
          const from = location.state?.from || '/';
          navigate(from);
        } else {
          setError(response.message);
        }
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError('An error occurred. Please try again later.');
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h4 className="mb-4">Login</h4>
          <Form>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={handleEmailChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={handlePasswordChange} />
            </Form.Group>

            <ButtonGroup>
              <Button variant="primary" type="submit" onClick={onSubmitClick}>Login</Button>
              <Button as={NavLink} variant="outline-secondary" to="/register">Register</Button>
              <Button as={NavLink} variant="outline-secondary" to="/forgot-password">Forgot Password</Button>
              <Button as={NavLink} variant="outline-secondary" to="/resend-confirmation">Resend Confirmation</Button>
            </ButtonGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
