import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { getPreviewFromLabelary } from './utils';

const LabelPreview = ({ widthInch, heightInch, dpi, content }) => {
  const [previewUrl, setPreviewUrl] = React.useState(null);

  const onRenderPreview = () => {
    const onImageLoad = (base64) => {
      setPreviewUrl(base64);
    };
    const onError = (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      setPreviewUrl(null);
    };

    getPreviewFromLabelary(
      content, dpi, widthInch, heightInch,
      onImageLoad, onError,
    );
  };

  React.useEffect(() => {
    if (content) {
      onRenderPreview(widthInch, heightInch, dpi, content);
    }
  }, [widthInch, heightInch, dpi, content]);

  return (
    <div className="zld-preview-container">
      <div className="zld-canvas-wrapper">
        {
          content && previewUrl ? (
            <img className="zld-preview" src={previewUrl} alt="ZPL Label Preview" />
          ) : (
            <Alert variant="info">
              <Alert.Heading>Preview will appear here</Alert.Heading>
              <p>
                We do not store any sensitive data on our servers.
                This is why you need to manually call preview update after page update.
              </p>
              <p>
                Click on &quot;Update Preview&quot; button to render.
              </p>
            </Alert>
          )
        }
      </div>
      <div>
        <small>
          Preview provided by
          {' '}
          <a href="http://labelary.com/" target="_blank" rel="noreferrer">Labelary</a>
        </small>
      </div>
    </div>
  );
};

LabelPreview.propTypes = {
  widthInch: PropTypes.number.isRequired,
  heightInch: PropTypes.number.isRequired,
  dpi: PropTypes.number.isRequired,
  content: PropTypes.string,
};

LabelPreview.defaultProps = {
  content: null,
};

export default LabelPreview;
