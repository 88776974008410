import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Navigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { reducer, sendRequest, useAuth } from '../utils';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const ResendConfirmation = () => {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();
  const [email, setEmail] = React.useState('');

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Navigate to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    if (!email) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Email is required' });
      return;
    }

    const opts = {
      email,
      recaptcha_token: token,
    };

    sendRequest('auth/confirmation/resend', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message });
      });
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h2 className="mb-4 text-orange">Resend Confirmation Email</h2>

          {
            state.error
              ? <div className="alert alert-danger" role="alert">{state.error}</div>
              : ''
          }

          {
            state.message
              ? <div className="alert alert-success" role="alert">{state.message}</div>
              : ''
          }

          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button type="submit" onClick={(onSubmitClick)}>Resend Confirmation</Button>

              {state.isLoading && <Spinner animation="border" />}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResendConfirmation;
