const DENSITY = {
  152: '6dpmm',
  203: '8dpmm',
  300: '12dpmm',
  600: '24dpmm',
};

function getPreviewFromLabelary(zpl, dpi, width, height, onSuccess, onError) {
  const labelaryUrl = `https://api.labelary.com/v1/printers/${DENSITY[dpi]}/labels/${width}x${height}/0/`;

  const formData = new FormData();
  formData.append('file', zpl);

  fetch(labelaryUrl, { method: 'POST', body: formData })
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        onSuccess(reader.result);
      };

      reader.onerror = () => {
        onError('Unable to download nested label image');
      };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      onError('Unable to download nested label image');
    });
}

export {
  DENSITY,
  getPreviewFromLabelary,
};
