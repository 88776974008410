import React from 'react';
import PropTypes from 'prop-types';

const ControlPanelButtonGroup = ({ element, name, controls }) => {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handleClick = (control) => {
    control.clickHandler(element);
    element.getLayer().fire('zld:change');

    // Force update to re-render the component
    forceUpdate();
  };

  return (
    <div className="mb-3">
      <h5 className="mb-2">{name}</h5>
      {
        controls.map((control) => (
          (!control.isVisible || control.isVisible(element)) && (
            control.widget
              ? <control.widget key={`${control.name}-${element.id()}`} element={element} />
              : (
                <button
                  key={control.name}
                  type="button"
                  className={`zld-control-button ${!control.isActive(element) ? 'inactive' : 'active'} mr-2`}
                  data-toggle="tooltip"
                  title={control.tooltip}
                  onClick={() => handleClick(control)}
                  disabled={!control.toggle && !control.isActive(element)}
                >
                  {
                control.isActive(element)
                  ? <img src={control.image} alt={control.name} />
                  : <img src={control.imageInactive} alt={control.name} />
                }
                </button>
              )
          )
        ))
      }
    </div>
  );
};

ControlPanelButtonGroup.propTypes = {
  element: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  controls: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    toggle: PropTypes.bool.isRequired,
    clickHandler: PropTypes.func,
    isActive: PropTypes.func,
    isVisible: PropTypes.func,
    image: PropTypes.string,
    imageInactive: PropTypes.string,
  })).isRequired,
};

export default ControlPanelButtonGroup;
