import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Navigate, NavLink } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';

import { useAuth, sendRequest, reducer } from '../utils';
import { SERVER_DOWN_ERROR } from '../constants';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const Register = () => {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    opts.recaptcha_token = token;

    sendRequest('auth/register', 'POST', opts, false)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE', error: SERVER_DOWN_ERROR }));
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h4 className="mb-4">Register</h4>
          <p>
            Already have an account?
            {' '}
            <NavLink to="/login">Login</NavLink>
          </p>
          <Form onSubmit={handleSubmit} className="mb-4">
            {state.isError && (
              <Alert variant="danger">
                {state.error}
              </Alert>
            )}

            {state.message && (
              <Alert variant="success">
                {state.message}
              </Alert>
            )}

            <Row>
              <Form.Group as={Col} className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="first_name" name="first_name" placeholder="Enter first name" required />
              </Form.Group>

              <Form.Group as={Col} className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="last_name" name="last_name" placeholder="Enter last name" required />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" required />
              <Form.Text className="text-muted">
                We&apos;ll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" placeholder="Password" required />
            </Form.Group>

            <Alert variant="warning">
              Password Requirements:
              <ul className="small">
                <li>Minimum 8 characters.</li>
                <li>The alphabets must be between [a-z]</li>
                <li>At least one alphabet should be of Upper Case [A-Z]</li>
                <li>At least 1 number or digit between [0-9].</li>
                <li>At least 1 character from [like _ , @ $ or other special symbol].</li>
              </ul>
            </Alert>

            <Form.Group className="mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button variant="primary" type="submit">Register</Button>

              {state.isLoading && <Spinner animation="border" />}
            </div>
          </Form>

        </Col>
      </Row>
    </Container>
  );
};

export default Register;
