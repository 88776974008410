import { createAuthProvider } from 'react-token-auth';

const { REACT_APP_API_URL } = process.env;

export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: 'access_token',
  onUpdateToken: (token) => fetch(`${REACT_APP_API_URL}/auth/refresh`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.refresh_token}`,
    },
  })
    .then((r) => r.json())
    .then((r) => r.data),
});

export function sendRequest(route, method, body = {}, withAuth = true, json = true) {
  let url = `${REACT_APP_API_URL}/${route}`;

  const params = {
    method,
    headers: json ? { 'Content-Type': 'application/json' } : {},
  };

  if (method === 'GET') {
    url += `?${new URLSearchParams(body)}`;
  } else if (json) {
    params.body = JSON.stringify(body);
  } else {
    params.body = body;
  }

  const fetchFun = withAuth ? authFetch : fetch;

  return fetchFun(url, params)
    .then((r) => r.text())
    .then((text) => {
      try {
        const data = JSON.parse(text);
        return data;
      } catch (err) {
        // This usually happens when the server can't process the request for some reason
        throw Error('Our API is down. Please try again later.');
      }
    });
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: action.payload || state.data,
        message: '',
        error: '',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload || state.data,
        message: action.message,
        error: '',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload || state.data,
        message: '',
        error: action.error,
      };
    default:
      throw new Error();
  }
};
