import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';
import { OBJECT_CONTROLS } from './constants';
import ControlPanelButtonGroup from './ControlPanelButtonGroup';

function calculateOffset(element) {
  const rect = element.getClientRect();
  const newLeft = element.getStage().container().offsetLeft + rect.x + rect.width + 10;
  const newTop = element.getStage().container().offsetTop + rect.y;

  return { top: newTop, left: newLeft };
}

const ControlPanel = ({ show, element }) => {
  const [state, setState] = React.useState({
    show,
    element,
    offset: { top: 0, left: 0 },
    groups: [],
  });

  React.useEffect(() => {
    setState({
      show,
      element,
      offset: element ? calculateOffset(element) : { top: 0, left: 0 },
      groups: element ? (OBJECT_CONTROLS[element.className || element.getType()] || []) : [],
    });
  }, [show, element]);

  const getStyles = () => ({
    position: 'absolute',
    width: '250px',
    top: state.offset.top,
    left: state.offset.left,
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '15px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  });

  return (
    <div className={`zld-control-panel-container ${state.show ? '' : 'd-none'}`} style={getStyles()}>
      <h4 className="mb-3">Settings</h4>

      {
        state.groups.length
          ? state.groups.map((group) => (
            <ControlPanelButtonGroup key={group.name} element={state.element} name={group.name} controls={group.controls} />
          ))
          : 'No settings available for this object.'
      }

      <span className="text-mute">
        <Alert variant="info pt-1 pb-1 pl-2 pr-2 mb-1">
          <small>Hover over the buttons to see more information.</small>
        </Alert>
      </span>
    </div>
  );
};

ControlPanel.propTypes = {
  show: PropTypes.bool.isRequired,
  element: PropTypes.object,
};

ControlPanel.defaultProps = {
  element: null,
};

export default ControlPanel;
