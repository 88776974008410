import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonGroup, Col, Row } from 'react-bootstrap';

import CustomFieldsSelector from './CustomFieldsSelector';
import AddNestedLabelModal from './AddNestedLabelModal';
import { getPreviewFromLabelary } from './utils';

const FIELDS_FOR_QUICK_BUTTONS = {
  'product.template': {
    Name: 'name',
    'Internal Reference': 'default_code',
    Barcode: 'barcode',
    'Sales Price': 'list_price',
    'Unit of Measure': 'uom_id.name',
  },
  'product.product': {
    Name: 'name',
    'Internal Reference': 'default_code',
    Barcode: 'barcode',
    'Sales Price': 'list_price',
    'Unit of Measure': 'uom_id.name',
  },
  'stock.production.lot': {
    'Lot Name': 'name',
    'Expiration Date': 'expiration_date',
    'Best Before Date': 'use_date',
    'Product → Name': 'product_id.name',
    'Product → Internal Reference': 'product_id.default_code',
    'Product → Barcode': 'product_id.barcode',
    'Product → Sales Price': 'product_id.list_price',
  },
  // This is the same model as 'stock.production.lot' but in Odoo 16.0 it has different name
  'stock.lot': {
    'Lot Name': 'name',
    'Expiration Date': 'expiration_date',
    'Best Before Date': 'use_date',
    'Product → Name': 'product_id.name',
    'Product → Internal Reference': 'product_id.default_code',
    'Product → Barcode': 'product_id.barcode',
    'Product → Sales Price': 'product_id.list_price',
  },
  'stock.quant.package': {
    Name: 'name',
  },
};

const EditorButtons = ({ model, connectionId, wrapperRef }) => {
  // Add nested label modal
  const [showAddNestedLabelModal, setShowAddNestedLabelModal] = React.useState(false);

  const handleCloseAddNestedLabelModal = () => {
    setShowAddNestedLabelModal(false);
  };

  const handleAddTextClick = (e) => {
    e.preventDefault();
    const text = e.target.dataset.value || 'Lorum ipsum';
    wrapperRef.current.addElement({ className: 'Text', attrs: { text, editable: true } });
  };

  const handleAddLineClick = (e) => {
    e.preventDefault();
    wrapperRef.current.addElement({ className: 'Line', attrs: { points: [50, 50, 100, 50], editable: true } });
  };

  const handleAddCustomField = (field) => {
    wrapperRef.current.addElement({ className: 'Text', attrs: { text: `%%${field}%%`, editable: true } });
  };

  const handleQuickButtonClick = (name) => {
    wrapperRef.current.addElement({ className: 'Text', attrs: { text: `%%${name}%%`, editable: true } });
  };

  const handleAddRectClick = (e) => {
    e.preventDefault();
    wrapperRef.current.addElement({ className: 'Rect', attrs: { width: 50, height: 50, editable: true } });
  };

  const handleAddNestedLabelClick = () => {
    setShowAddNestedLabelModal(true);
  };

  const handleAddNestedLabel = ({ field, label }) => {
    setShowAddNestedLabelModal(false);

    const onFetchImageSuccess = (base64) => {
      wrapperRef.current.addElement({
        className: 'Group',
        attrs: {
          x: 0,
          y: 0,
          width: label.width * label.dpi,
          height: label.height * label.dpi,
          graggable: true,
          data: {
            field,
            label_id: label._id,
          },
          type: 'NestedLabel',
        },
        children: [
          {
            className: 'Image',
            attrs: {
              url: base64,
              x: 0,
              y: 0,
              width: label.width * label.dpi,
              height: label.height * label.dpi,
              draggable: false,
            },
          },
          {
            className: 'Rect',
            attrs: {
              x: 0,
              y: 0,
              width: label.width * label.dpi,
              height: label.height * label.dpi,
              strokeWidth: 1,
              stroke: 'black',
              dash: [10, 10],
              draggable: false,
            },
          },
        ],
      });
    };

    const onFetchImageError = (error) => {
      alert(error);
    };

    getPreviewFromLabelary(
      label.zpl, label.dpi, label.width, label.height,
      onFetchImageSuccess, onFetchImageError,
    );
  };

  return (
    <>
      {/* Quick Button */}
      <Row className="mt-4">
        <ButtonGroup>
          {
        FIELDS_FOR_QUICK_BUTTONS[model] && Object.entries(FIELDS_FOR_QUICK_BUTTONS[model]).map(([label, name]) => (
          <Button variant="outline-primary" key={name} onClick={() => handleQuickButtonClick(name)}>
            {label}
          </Button>
        ))
      }
        </ButtonGroup>
      </Row>

      <hr />

      {/* Default Buttons */}
      <Row>
        <ButtonGroup>
          <Button variant="outline-primary" onClick={handleAddTextClick}>Add Custom Text</Button>
          <Button variant="outline-primary" onClick={handleAddRectClick}>Add Rectangle</Button>
          <Button variant="outline-primary" onClick={handleAddLineClick}>Add Line</Button>
          <Button variant="outline-primary" onClick={handleAddNestedLabelClick}>Add Nested Label</Button>
        </ButtonGroup>

        <span className="mt-2 mb-2"> or select custom field: </span>

        <CustomFieldsSelector connectionId={connectionId} model={model} onAddCustomField={handleAddCustomField} />

        <Col md="12">
          <Alert variant="info" className="mt-4">
            NOTE: In order to add image to your ZPL label drag&drop it to the above area.
            We support PNG and JPEG images (PNG gives the best quality).
            Note that color images will be automatically converted to black and white format
            as they are only supported by ZPL label printers. Maximum allowed size is 1 MB.
          </Alert>
        </Col>
      </Row>

      {/* Add nested label modal */}
      <AddNestedLabelModal
        model={model}
        connectionId={connectionId}
        show={showAddNestedLabelModal}
        onClose={handleCloseAddNestedLabelModal}
        onSubmit={handleAddNestedLabel}
      />
    </>
  );
};

EditorButtons.propTypes = {
  model: PropTypes.string.isRequired,
  connectionId: PropTypes.string.isRequired,
  wrapperRef: PropTypes.shape({
    current: PropTypes.shape({
      addElement: PropTypes.func.isRequired,
    }),
  }).isRequired,
};

export default EditorButtons;
