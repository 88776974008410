import React from 'react';

const HowTo = () => (
  <div>
    <h1>How to use Odoo</h1>
    <p>Odoo is a great tool for managing your business.</p>
  </div>
);

export default HowTo;
