import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { login, reducer, sendRequest, useAuth } from '../utils';
import { SERVER_DOWN_ERROR } from '../constants';

// eslint-disable-next-line max-len
const PASSWORD_REGEX = /^(?=(.*[a-z]))(?=(.*[A-Z]))(?=(.*[0-9]))(?=(.*[!"\\#$%&'()*+,-./:;<=>?@[\]^_`{|}~]))[A-Za-z\d!"\\#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

const ResetPassword = () => {
  const [logged] = useAuth();
  const { token } = useParams();

  const [validated, setValidated] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  const formRef = React.useRef(null);
  const passwordConfirmationRef = React.useRef(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    sendRequest('auth/password/forgot/confirm', 'POST', { token, password })
      .then((response) => {
        if (response.status_code === 200) {
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: SERVER_DOWN_ERROR });
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    if (PASSWORD_REGEX.test(e.target.value)) {
      e.target.setCustomValidity('');
    } else {
      e.target.setCustomValidity(false);
    }

    // Update validation of password confirmation
    if (passwordConfirmationRef.current) {
      if (e.target.value !== passwordConfirmation) {
        passwordConfirmationRef.current.setCustomValidity(false);
      } else {
        passwordConfirmationRef.current.setCustomValidity('');
      }
    }

    setValidated(true);
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);

    if (e.target.value !== password) {
      e.target.setCustomValidity(false);
    } else {
      e.target.setCustomValidity('');
    }
    setValidated(true);
  };

  if (logged) {
    return <Navigate to="/" />;
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h2 className="mb-4">Reset Password</h2>

          {state.error && <Alert variant="danger">{state.error}</Alert>}
          {state.message && <Alert variant="success">{state.message}</Alert>}

          <Form validated={validated} onSubmit={handleSubmit} className="mb-4" ref={formRef}>

            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  required
                  id="password"
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Password doesn&apos;t match requirements</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>New Password Confirmation</Form.Label>
                <Form.Control
                  required
                  id="confirm_password"
                  type="password"
                  placeholder="New Password Confirmation"
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  ref={passwordConfirmationRef}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Passwords don&apos;t match</Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Alert variant="info">
              Password Requirements:
              <ul className="small mb-0">
                <li>Minimum 8 characters.</li>
                <li>The alphabets must be between [a-z]</li>
                <li>At least one alphabet should be of Upper Case [A-Z]</li>
                <li>At least 1 number or digit between [0-9].</li>
                <li>At least 1 character from [like _ ,  @ $ or other special symbol].</li>
              </ul>
            </Alert>

            <Button type="submit" disabled={!(formRef.current && formRef.current.checkValidity())}>Save</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
