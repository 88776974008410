const FONTS = ['Roboto Condensed', 'Roboto Mono'];
const UNKNOWN_ERROR_MESSAGE = 'Something went wrong. Please try again or contact us: support@ventor.tech.';
const NO_LABEL_ERROR = 'Label with such ID not found or you do not have access to it.';
const SERVER_DOWN_ERROR = `ZPL Label Converter API is on maintenance. Try again in a few minutes.
If the issue will not be solved, please, drop us an email at support@ventor.tech`;

export {
  FONTS,
  UNKNOWN_ERROR_MESSAGE,
  NO_LABEL_ERROR,
  SERVER_DOWN_ERROR,
};
