import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { reducer, sendRequest } from '../../utils';
import { UNKNOWN_ERROR_MESSAGE } from '../../constants';
import Loader from '../../Common/Loader';

const AddNestedLabelModal = ({ model, connectionId, show, onSubmit, onClose }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: { fields: [], labels: [] },
      message: '',
      error: '',
      isLoading: true,
      isError: false,
    },
  );

  const [field, setField] = React.useState(null);
  const [label, setLabel] = React.useState(null);

  const handleFieldChange = (e) => {
    const fieldName = e.target.value;
    const selectedField = state.data.fields.find((f) => f.name === fieldName);
    setField(selectedField);
    setLabel(null);
  };

  const handleLabelChange = (e) => {
    const labelName = e.target.value;
    const selectedLabel = state.data.labels.find((l) => l._id === labelName);
    setLabel(selectedLabel);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit({ field, label });
  };

  React.useEffect(() => {
    if (show) {
      dispatch({ type: 'FETCH_INIT' });
      // Fetch fields for model
      sendRequest(`connections/${connectionId}/fields/${model}`, 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            const fields = response.data;
            const filteredFields = fields.filter((field) => ['one2many', 'many2many'].includes(field.type));

            dispatch({
              type: 'FETCH_SUCCESS',
              payload: { fields: filteredFields, labels: [] },
            });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.log(error);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  }, [show, connectionId, model]);

  React.useEffect(() => {
    if (field) {
      // Load list of labels for selected field
      sendRequest('labels', 'GET', { model: field.comodel, connection_id: connectionId })
        .then((response) => {
          if (response.status_code === 200) {
            const labels = response.data;

            dispatch({
              type: 'FETCH_SUCCESS',
              payload: { fields: state.data.fields, labels },
            });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.log(error);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  }, [field]);

  // Render react bootstrap modal
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Nested Label</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {state.isError && <p>{state.error}</p>}

        {/* Show warning when no fields found */}
        {
          !state.isError && state.data.fields.length === 0 && (
            <Alert variant="warning">
              No fields found for model:
              {' '}
              <strong>{model}</strong>
              <br />
              There are two possible reasons for this:
              <ul>
                <li>
                  The model does not have any fields of type
                  {' '}
                  <strong>one2many</strong>
                  {' '}
                  or
                  {' '}
                  <strong>many2many</strong>
                </li>
                <li>
                  Your Odoo module version is not supports this feature.
                  Please upgrade to the version 1.3.0 or above.
                </li>
              </ul>
            </Alert>
          )
        }

        {
          state.isLoading
            ? <Loader />
            : (
              <Form>
                <Form.Group controlId="formField" className="mb-3">
                  <Form.Label>Field</Form.Label>
                  <Form.Select aria-label="Select field" onChange={handleFieldChange}>
                    <option value="">Select Field</option>
                    {state.data.fields.map((field) => <option key={field.name} value={field.name}>{`${field.label} (${field.name})`}</option>)}
                  </Form.Select>
                </Form.Group>

                {
                  !field && (
                    <Alert variant="info">
                      Please select a field to add a nested label
                    </Alert>
                  )
                }

                {field && state.data.labels.length > 0 && (
                  <Form.Group controlId="formLabel">
                    <Form.Label>Label</Form.Label>
                    <Form.Select aria-label="Select label" onChange={handleLabelChange}>
                      <option value="">Select Label</option>
                      {state.data.labels.map((label) => <option key={label._id} value={label._id}>{label.name}</option>)}
                    </Form.Select>
                  </Form.Group>
                )}

                {
                  field && state.data.labels.length === 0 && (
                    <Alert variant="warning">
                      No labels found for model:
                      {' '}
                      <strong>{field.comodel}</strong>
                      <br />
                      Please create a label for the model first.
                    </Alert>
                  )
                }
              </Form>
            )
        }

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSubmit} disabled={!field || !label}>
          Add Label
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddNestedLabelModal.propTypes = {
  model: PropTypes.string.isRequired,
  connectionId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddNestedLabelModal;
