/* eslint-disable no-underscore-dangle */
const { Konva } = window;

/* eslint-disable no-restricted-syntax */
export default class KonvaSerializer {
  static serialize(layer) {
    const nodes = layer.getChildren();

    // Use getAttrs instead of toObject to avoid skipping some properties
    // (like skipping height if it equal to fontSize)
    const content = nodes
      .filter((node) => node.getClassName() !== 'Transformer')
      .map((node) => {
        if (node.getClassName() === 'Group') {
          return KonvaSerializer.serializeContainer(node);
        }
        return this.serializeNode(node);
      });

    return content;
  }

  // This is a copy of Konva toObject method with some changes to avoid skipping properties with
  // default values
  static serializeNode(node) {
    const obj = {};
    obj.attrs = {};

    let key;
    let val;
    let nonPlainObject;

    const attrs = node.getAttrs();
    // eslint-disable-next-line guard-for-in
    for (key in attrs) {
      val = attrs[key];

      nonPlainObject = Konva.Util.isObject(val) && !Konva.Util._isPlainObject(val) && !Konva.Util._isArray(val);
      if (nonPlainObject) {
        continue;
      }
      obj.attrs[key] = val;
    }
    obj.className = node.getClassName();

    return Konva.Util._prepareToStringify(obj);
  }

  static serializeContainer(container) {
    const obj = KonvaSerializer.serializeNode(container);

    obj.children = [];

    container.getChildren().forEach((child) => {
      obj.children.push(KonvaSerializer.serializeNode(child));
    });
    return Konva.Util._prepareToStringify(obj);
  }
}
