import React from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { reducer, sendRequest } from '../utils';
import LabelEditor from './Helpers/LabelEditor';
import LabelPreview from './Helpers/LabelPreview';
import { UNKNOWN_ERROR_MESSAGE, NO_LABEL_ERROR } from '../constants';
import ConnectionStatus from './Helpers/ConnectionStatus';
import Loader from '../Common/Loader';

const EditLabel = () => {
  const { labelId } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {},
      message: '',
      error: '',
      isLoading: false,
      isError: false,
    },
  );
  const [content, setContent] = React.useState(null);
  const [preview, setPreview] = React.useState(null);
  const [changed, setChanged] = React.useState(false);

  const handleCancelClick = (e) => {
    e.preventDefault();

    // Ask for confirmation
    if (window.confirm('Are you sure you want to go back? All unsaved changes will be lost.')) {
      navigate('/');
    }
  };

  const handleCanvasChange = React.useCallback((content) => {
    setContent(content);
    setChanged(true);
  });

  const handleFieldChange = (field, value) => {
    dispatch({
      type: 'FETCH_SUCCESS',
      payload: { ...state.data, [field]: value },
    });
    setChanged(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    // Validate form
    if (!state.data.name || !state.data.width || !state.data.height || !state.data.dpi) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please fill all required fields' });
      return;
    }

    const data = { ...state.data, content };

    sendRequest(`labels/${state.data._id}`, 'PUT', data)
      .then((response) => {
        if (response.status_code === 200) {
          const label = response.data;

          dispatch({
            type: 'FETCH_SUCCESS',
            message: 'Label saved successfully',
            payload: label,
          });
          setChanged(false);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      }).catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  };

  const handleUpdatePreviewClick = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`labels/${state.data._id}/preview`, 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          const { preview } = response.data;

          dispatch({ type: 'FETCH_SUCCESS' });
          setPreview(preview);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  };

  const handlePublishClick = async (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`labels/${state.data._id}/publish`, 'POST')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({
            type: 'FETCH_SUCCESS',
            message: 'Label published successfully',
            payload: { ...state.data, ...response.data },
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  };

  const handleOpenInOdooClick = (e) => {
    e.preventDefault();

    // Open label in new tab
    window.open(state.data.odoo_label_url, '_blank');
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`labels/${labelId}`, 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          const label = response.data;
          setContent(label.content);
          dispatch({ type: 'FETCH_SUCCESS', payload: label });
        } else if (response.status_code === 422) {
          dispatch({ type: 'FETCH_FAILURE', error: NO_LABEL_ERROR });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, [labelId]);

  if (state.isLoading && !Object.keys(state.data).length) {
    // Initial loading
    return <Loader />;
  }

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="12">
          <h2 className="mb-4 d-flex justify-content-between align-items-center">
            Edit Label
            <ConnectionStatus connectionId={state.data.connection_id} />
          </h2>
          <Form onSubmit={(e) => { e.preventDefault(); }}>

            {state.error && (
              <Alert variant="danger">
                {state.error}
              </Alert>
            )}

            {state.message && (
              <Alert variant="success">
                {state.message}
              </Alert>
            )}

            <div className="d-flex align-items-center mb-4">
              <ButtonGroup className="me-4">
                <Button variant="primary" type="submit" onClick={handleSaveClick}>Save</Button>
                {
                  changed ? (
                    <Button as={NavLink} to="/" variant="outline-primary" className="disabled">
                      Save label to Preview or Publish to Odoo
                    </Button>
                  ) : (
                    <>
                      <Button variant="outline-primary" type="submit" onClick={handleUpdatePreviewClick}>Update Preview</Button>
                      <Button as={NavLink} to="/" variant="outline-primary" onClick={handlePublishClick}>
                        {state.data.odoo_label_id ? 'Update in Odoo' : 'Publish to Odoo'}
                      </Button>
                    </>
                  )
                }

                {
                  state.data.odoo_label_id && (
                    <Button
                      as={NavLink}
                      to="/"
                      variant="outline-primary"
                      onClick={handleOpenInOdooClick}
                    >
                      Open in Odoo
                    </Button>
                  )
                }

                <Button as={NavLink} to="/" variant="outline-primary" type="submit" onClick={handleCancelClick}>Cancel</Button>
              </ButtonGroup>

              {state.isLoading && <div className="spinner-border ms-auto" role="status" aria-hidden="true" />}
            </div>

            {!state.isLoading && Object.keys(state.data).length && (
              <>
                {/* Parameters */}
                <Row>
                  <Form.Group as={Col} className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter label name"
                      defaultValue={state.data.name}
                      onChange={(e) => handleFieldChange('name', e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="model">
                    <Form.Label>Model</Form.Label>
                    <Form.Control type="text" name="model" value={state.data.model.label} disabled />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="orientation">
                    <Form.Label>Orientation</Form.Label>
                    <Form.Select
                      id="orientation"
                      name="orientation"
                      defaultValue={state.data.orientation}
                      onChange={(e) => handleFieldChange('orientation', e.target.value)}
                    >
                      <option value="normal">Normal</option>
                      <option value="inverted">Inverted</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} className="mb-3" controlId="name">
                    <Form.Label>Width, inches</Form.Label>
                    <Form.Control
                      type="number"
                      name="width"
                      placeholder="Enter width"
                      min="0.1"
                      step="0.1"
                      defaultValue={state.data.width}
                      onChange={(e) => handleFieldChange('width', Number(e.target.value))}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="model">
                    <Form.Label>Height, inches</Form.Label>
                    <Form.Control
                      type="number"
                      name="height"
                      placeholder="Enter height"
                      min="0.1"
                      step="0.1"
                      defaultValue={state.data.height}
                      onChange={(e) => handleFieldChange('height', Number(e.target.value))}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="dpi">
                    <Form.Label>DPI</Form.Label>
                    <Form.Select
                      id="dpi"
                      name="dpi"
                      defaultValue={state.data.dpi}
                      onChange={(e) => handleFieldChange('dpi', Number(e.target.value))}
                      required
                    >
                      <option value="152">6dpmm (152 dpi)</option>
                      <option value="203">8dpmm (203 dpi)</option>
                      <option value="300">12dpmm (300 dpi)</option>
                      <option value="600">24dpmm (600 dpi)</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3" controlId="encoding">
                    <Form.Label>Encoding</Form.Label>
                    <Form.Select
                      id="encoding"
                      name="encoding"
                      onChange={(e) => handleFieldChange('encoding', Number(e.target.value))}
                      defaultValue={state.data.encoding}
                      required
                    >
                      <option value="0">U.S.A. 1 Character Set</option>
                      <option value="1">U.S.A. 2 Character Set</option>
                      <option value="2">U.K. Character Set</option>
                      <option value="3">Holland Character Set</option>
                      <option value="4">Denmark/Norway Character Set</option>
                      <option value="5">Sweden/Finland Character Set</option>
                      <option value="6">Germany Character Set</option>
                      <option value="7">France 1 Character Set</option>
                      <option value="8">France 2 Character Set</option>
                      <option value="9">Italy Character Set</option>
                      <option value="10">Spain Character Set</option>
                      <option value="11">Miscellaneous Character Set</option>
                      <option value="12">Japan (ASCII with Yen symbol) Character Set</option>
                      <option value="14">Double Byte Asian Encodings</option>
                      <option value="28">Unicode (UTF-8) Character Set (recommended)</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                {/* Editor */}
                <Row className="mt-4">
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>Editor</Form.Label>
                    <LabelEditor
                      widthInch={state.data.width}
                      heightInch={state.data.height}
                      dpi={state.data.dpi}
                      model={state.data.model.value}
                      defaultContent={state.data.content}
                      connectionId={state.data.connection_id}
                      onChange={handleCanvasChange}
                    />
                  </Form.Group>
                </Row>

                {/* Preview */}
                <Row className="mt-4">
                  <Form.Group as={Col} className="mb-3" controlId="name">
                    <Form.Label>Preview</Form.Label>
                    <LabelPreview widthInch={state.data.width} heightInch={state.data.height} dpi={state.data.dpi} content={preview} />
                  </Form.Group>
                </Row>

                {/* ZPL Content */}
                <Row className="mt-4">
                  <Form.Group as={Col} className="mb-3" controlId="name">
                    <Form.Label>ZPL Preview</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      placeholder="This is preview of ZPL label content"
                      value={state.data.preview}
                      // Select all text on click
                      onClick={(e) => e.target.select()}
                      readOnly
                    />
                    <Form.Text className="text-muted">
                      This is ZPL label content. You can copy it and paste to your ZPL editor.
                    </Form.Text>
                  </Form.Group>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditLabel;
