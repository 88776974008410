import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const handleGoHomeClick = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <Alert variant="danger">
      <h1 className="mb-4">Ooops. Something went wrong!</h1>
      <pre>{error.message}</pre>

      <Button onClick={resetErrorBoundary}>Try Again</Button>
      <span className="me-2 mx-2"> or </span>
      <Button variant="outline-primary" onClick={handleGoHomeClick}>Go Home</Button>
      {/* Show contact us information */}
      <p className="lead mt-4">
        If the problem persists, please contact us:
        {' '}
        <a href="mailto:support@ventor.tech">
          support@ventor.tech
        </a>
      </p>
    </Alert>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorFallback;
