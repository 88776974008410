import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from '../utils';
import { useUser } from '../hooks';

const Menu = ({ logged }) => {
  const user = useUser();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="/logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top me-3"
          />
          ZPL Label Designer
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="me-auto">
            {logged && (
              <>
                <Nav.Link as={NavLink} to="/">Labels</Nav.Link>
                <Nav.Link as={NavLink} to="/connections">Odoo Connections</Nav.Link>
              </>
            )}
          </Nav>

          <Nav className="">
            {logged && user ? (
              <NavDropdown title={`${user.first_name} ${user.last_name} (${user.email})`} id="actions-nav-dropdown" className="d-flex">
                <NavDropdown.Item onClick={() => { logout(); localStorage.clear(); }}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

Menu.propTypes = {
  logged: PropTypes.bool.isRequired,
};

export default Menu;
