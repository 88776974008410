/* eslint-disable max-classes-per-file */
import { FONTS } from '../../constants';
import KonvaSerializer from './serializer';

const { Konva } = window;

const MIN_WIDTH = 20;
const DEFAULT_TRANSFORMER_CONFIG = {
  rotateEnabled: true,
  rotationSnaps: [0, 90, 180, 270],
  boundBoxFunc: (oldBoundBox, newBoundBox) => {
    if (Math.abs(newBoundBox.rotation % (Math.PI / 2)) > 0) {
      return oldBoundBox;
    }
    return newBoundBox;
  },
};

// TODO: Investigate different browsers?
class CanvasTextarea {
  /*
    Helper class to create textarea over canvas element while editing text
  */
  constructor(element, onShow, onHide) {
    this.element = element;
    this.onShow = onShow;
    this.onHide = onHide;
  }

  show() {
    this.setup();

    if (this.onShow) this.onShow();
  }

  hide(saveChanges = true) {
    let content = null;
    if (saveChanges) {
      content = this.textarea.value;
    }
    if (this.onHide) this.onHide(content);

    this.destroy();
  }

  setup() {
    // Get absolute position of canvas element
    const elementPosition = this.element.absolutePosition();
    this.position = {
      x: elementPosition.x + this.element.getStage().container().offsetLeft,
      y: elementPosition.y + this.element.getStage().container().offsetTop,
    };

    // Create textarea element and style it
    this.textarea = document.createElement('textarea');
    this.textarea.className = 'zld-canvas-textarea';
    document.body.appendChild(this.textarea);

    // Update textarea properties
    this.textarea.value = this.element.text();

    Object.assign(this.textarea.style, {
      position: 'absolute',
      top: `${this.position.y}px`,
      left: `${this.position.x}px`,
      width: `${this.element.width() - this.element.padding() * 2}px`,
      height: `${this.element.height() - this.element.padding() * 2 + 5}px`,
      fontSize: `${this.element.fontSize()}px`,
      lineHeight: this.element.lineHeight(),
      fontFamily: this.element.fontFamily(),
      textAlign: this.element.align(),
    });

    const rotation = this.element.rotation();
    let transform = '';
    if (rotation) {
      transform += `rotateZ(${rotation}deg)`;
    }

    const px = 0;
    // also we need to slightly move textarea on firefox
    // because it jumps a bit
    // const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // if (isFirefox) {
    //   px += 2 + Math.round(element.fontSize() / 20);
    // }
    transform += `translateY(-${px}px)`;

    this.textarea.style.transform = transform;

    // Update height
    this.textarea.style.height = 'auto';
    // this.textarea.style.height = `${this.textarea.scrollHeight + 3}px`;
    this.textarea.style.height = `${this.textarea.scrollHeight + this.element.fontSize()}px`;

    this.textarea.focus();

    this.textarea.addEventListener('keydown', this.onKeyDown.bind(this));

    // Save modified onClick function
    this.onClickBinded = this.onClick.bind(this);

    // Add click listener to window. Used setTimeout to avoid triggering click event on textarea
    // before it's added to DOM
    setTimeout(() => {
      window.addEventListener('click', this.onClickBinded);
    });
  }

  destroy() {
    if (!this.textarea) return;
    this.textarea.parentNode.removeChild(this.textarea);
    window.removeEventListener('click', this.onClickBinded);
  }

  setTextareaWidth(newWidth) {
    if (!newWidth) {
      // set width for placeholder
      newWidth = this.element.placeholder.length * this.element.fontSize();
    }
    // some extra fixes on different browsers
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // if (isSafari || isFirefox) {
    //   newWidth = Math.ceil(newWidth);
    // }

    // const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    // if (isEdge) {
    //   newWidth += 1;
    // }
    this.textarea.style.width = `${newWidth}px`;
  }

  onKeyDown(e) {
    // Don't hide on Shift + Enter
    if (e.key === 'Enter' && !e.shiftKey) {
      this.hide();
      return;
    }

    // Don't update value of element on Esc
    if (e.key === 'Escape') {
      this.hide(false);
      return;
    }

    // Update height of textarea
    const scale = this.element.getAbsoluteScale().x;
    this.setTextareaWidth(this.element.width() * scale);
    this.textarea.style.height = 'auto';
    this.textarea.style.height = `${this.textarea.scrollHeight + this.element.fontSize()}px`;
  }

  onClick(e) {
    if (e.target !== this.textarea) {
      this.hide();
    }
  }
}

export default class CanvasWrapper {
  constructor({
    canvasEl,
    dpi, width, height,
    content,
    onChange, onLeftClickEvent, onContextmenuEvent, onContentReady,
  }) {
    this.canvasEl = canvasEl;
    this.dpi = dpi;
    this.width = width;
    this.height = height;
    this.content = content;

    this.onChange = onChange;
    this.onLeftClickEvent = onLeftClickEvent;
    this.onContextmenuEvent = onContextmenuEvent;
    this.onContentReady = onContentReady;

    this.withGrid = false;

    // Editable text
    this.textarea = null;

    // Initialize canvas
    this.stage = new Konva.Stage({
      container: 'zld-canvas',
      width: this.width,
      height: this.height,
    });
    this.layer = new Konva.Layer();
    this.devLayer = new Konva.Layer();

    this.stage.add(this.devLayer);
    this.stage.add(this.layer);

    if (this.content.length > 0) {
      this.loadContent(this.content);
    }

    // Make canvas focusable
    this.stage.container().tabIndex = 0;

    this.initializeTransformers();
    this.initializeEvents();

    // eslint-disable-next-line no-console
    console.log('Wrapper', this);
  }

  initializeTransformers() {
    const rectTransformer = new Konva.Transformer({
      ...DEFAULT_TRANSFORMER_CONFIG,
      rotateEnabled: false,
    });

    const lineTransformer = new Konva.Transformer({
      ...DEFAULT_TRANSFORMER_CONFIG,
      // ignoreStroke: true,
      // enabledAnchors: ['top-center', 'bottom-center'],
      // Allow to change only width of line
      // resizeEnabled: false,
      enabledAnchors: ['middle-left', 'middle-right'],
    });

    const textTransformer = new Konva.Transformer({
      ...DEFAULT_TRANSFORMER_CONFIG,
    });

    const imageTransformer = new Konva.Transformer({
      rotateEnabled: true,
      rotationSnaps: [0, 90, 180, 270],
    });

    const groupTransformer = new Konva.Transformer({
      ...DEFAULT_TRANSFORMER_CONFIG,
      enabledAnchors: [],
      rotateEnabled: false,
    });

    this.transformers = {
      Rect: rectTransformer,
      Line: lineTransformer,
      Text: textTransformer,
      Image: imageTransformer,
      Group: groupTransformer,
    };

    this.layer.add(rectTransformer);
    this.layer.add(lineTransformer);
    this.layer.add(textTransformer);
    this.layer.add(imageTransformer);
    this.layer.add(groupTransformer);

    // Transforming events
    this.transformers.Rect.on('transformend', () => this.handleContentChange());
    this.transformers.Line.on('transformend', () => this.handleContentChange());
    this.transformers.Text.on('transformend', () => this.handleContentChange());
    this.transformers.Image.on('transformend', () => this.handleContentChange());
    this.transformers.Group.on('transformend', () => this.handleContentChange());
  }

  initializeEvents() {
    this.stage.on('click tap', this.handleClickEvent.bind(this));
    this.layer.on('contextmenu', this.handleContextmenuEvent.bind(this));
    this.layer.on('dragmove', this.handleDragMoveEvent.bind(this));
    this.layer.on('dragend', this.handleDragEndEvent.bind(this));
    this.layer.on('dblclick dbltap', this.handleDblClickEvent.bind(this));

    // Listen to custom change event
    this.layer.on('zld:change', this.handleContentChange.bind(this));
  }

  loadContent(content) {
    content.forEach((element) => {
      this.addElement(element, false);
    });
    this.onContentReady(this.layer.getChildren());
  }

  setDimensions(width, height, dpi) {
    this.width = width;
    this.height = height;
    this.dpi = dpi;

    this.stage.setWidth(this.width);
    this.stage.setHeight(this.height);
  }

  addElement(element, triggerChange = true) {
    const { className, attrs, children } = element;

    // Add custom name to each element
    if (!attrs.name) {
      attrs.name = 'element';
    }

    switch (className) {
      case 'Text':
        this.addText(attrs);
        break;
      case 'Rect':
        this.addRect(attrs);
        break;
      case 'Line':
        this.addLine(attrs);
        break;
      case 'Image':
        this.addImage(attrs);
        break;
      case 'Group':
        this.addGroup(attrs, children);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`Unknown element type: ${className}`);
        break;
    }

    if (triggerChange) {
      this.handleContentChange();
    }
  }

  /* Methods to work with canvas elements */
  addText(attrs) {
    const text = new Konva.Text({
      id: CanvasWrapper.generateUniqueID(),
      x: 50,
      y: 50,
      width: 200,
      height: 20,
      fontSize: 20,
      fontFamily: FONTS[0],
      draggable: true,
      ...attrs,
    });

    text.on('transform', CanvasWrapper.handleElementTransformEvent.bind(this));

    this.layer.add(text);
  }

  addRect(attrs) {
    const rect = new Konva.Rect({
      id: CanvasWrapper.generateUniqueID(),
      x: 100,
      y: 100,
      fill: null,
      stroke: 'black',
      strokeWidth: 5,
      strokeScaleEnabled: false,
      draggable: true,
      ...attrs,
    });

    rect.on('transform', CanvasWrapper.handleElementTransformEvent.bind(this));

    this.layer.add(rect);
  }

  addLine(attrs) {
    const line = new Konva.Line({
      id: CanvasWrapper.generateUniqueID(),
      stroke: 'black',
      strokeWidth: 5,
      strokeScaleEnabled: false,
      draggable: true,
      shadowEnabled: false,
      ...attrs,
    });

    line.on('transform', CanvasWrapper.handleElementTransformEvent.bind(this));

    this.layer.add(line);
    return line;
  }

  addImage(attrs) {
    const { url } = attrs;

    Konva.Image.fromURL(url, (image) => {
      image.setAttrs({
        id: CanvasWrapper.generateUniqueID(),
        x: 5,
        y: 5,
        draggable: true,
        ...attrs,
      });
      this.layer.add(image);
    });
  }

  addGroup(attrs, children) {
    const group = new Konva.Group({
      id: CanvasWrapper.generateUniqueID(),
      x: 100,
      y: 100,
      draggable: true,
      ...attrs,
    });

    children.forEach((child) => {
      const { className, attrs } = child;

      let element;

      switch (className) {
        case 'Text':
          element = CanvasWrapper.createText(attrs);
          group.add(element);
          break;
        case 'Rect':
          element = CanvasWrapper.createRect(attrs);
          group.add(element);
          break;
        case 'Image':
          CanvasWrapper.createImage(attrs, (image) => {
            group.add(image);
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('Unknown element type:', className);
          break;
      }
    });

    this.layer.add(group);

    // group.on('dragend', () => {
    //   group.position({
    //     x: Math.round(group.x() / M_TO_PX) * M_TO_PX,
    //     y: Math.round(group.y() / M_TO_PX) * M_TO_PX,
    //   });
    //   this.stage.batchDraw();
    //   this.handleContentChange();
    // });
  }

  /* Methods to work with canvas elements */
  static createText(attrs) {
    return new Konva.Text({
      id: CanvasWrapper.generateUniqueID(),
      x: 50,
      y: 50,
      width: 200,
      height: 20,
      fontSize: 20,
      fontFamily: FONTS[0],
      draggable: true,
      ...attrs,
    });
  }

  static createRect(attrs) {
    return new Konva.Rect({
      id: CanvasWrapper.generateUniqueID(),
      x: 100,
      y: 100,
      fill: null,
      stroke: 'black',
      strokeWidth: 5,
      strokeScaleEnabled: false,
      draggable: true,
      shadowColor: 'black',
      shadowBlur: 2,
      shadowOffset: { x: 1, y: 1 },
      shadowOpacity: 0.4,
      ...attrs,
    });
  }

  static createImage(attrs, callback) {
    const { url } = attrs;

    Konva.Image.fromURL(url, (image) => {
      image.setAttrs({
        id: CanvasWrapper.generateUniqueID(),
        draggable: true,
        ...attrs,
      });
      callback(image);
    });
  }

  remove(element) {
    // Reset transform
    const tr = this.layer.find('Transformer').find((tr) => tr.nodes()[0] === element);
    if (tr) {
      tr.nodes([]);
    }

    // Remove element
    element.destroy();

    // Notify about changes
    this.handleContentChange();
  }

  getActiveElement() {
    return this.layer.findOne('.selected');
  }

  resetSelection() {
    const selectedElement = this.getActiveElement();
    if (selectedElement) {
      selectedElement.removeName('selected');
      this.transformers[selectedElement.className || selectedElement.getType()].nodes([]);
    }
  }

  /* Grid */
  addGrid() {
    if (this.withGrid) {
      return;
    }

    this.withGrid = true;
    this.removeGrid();

    const DPMM = this.dpi / 25.4;

    for (let i = 0; i < Math.round(this.width / DPMM); i += 1) {
      this.devLayer.add(new Konva.Line({
        points: [Math.round(i * DPMM) + 0.5, 0, Math.round(i * DPMM) + 0.5, this.height],
        stroke: '#eee',
        strokeWidth: 1,
      }));
    }

    // this.devLayer.add(new Konva.Line({ points: [0, 0, 10, 10] }));
    for (let j = 0; j < Math.round(this.height / DPMM); j += 1) {
      this.devLayer.add(new Konva.Line({
        points: [0, Math.round(j * DPMM), this.width, Math.round(j * DPMM)],
        stroke: '#eee',
        strokeWidth: 0.5,
      }));
    }

    // this.devLayer.batchDraw();
  }

  removeGrid() {
    this.withGrid = false;
    this.devLayer.destroyChildren();
    this.devLayer.draw();
  }

  /* Snap (Adapted from https://konvajs.org/docs/sandbox/Objects_Snapping.html) */
  getLineGuideStops(skipShape) {
    // Snap to stage borders and the center of the stage
    const vertical = [0, this.stage.width() / 2, this.stage.width()];
    const horizontal = [0, this.stage.height() / 2, this.stage.height()];

    // Snap over edges and center of each object on the canvas
    this.layer.find('.element').forEach((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }
      const box = guideItem.getClientRect();
      // Snap to all edges of shapes
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }

  static getObjectSnappingEdges(node) {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  }

  getGuides(lineGuideStops, itemBounds) {
    const GUIDELINE_OFFSET = this.dpi / 25.4;
    const resultV = [];
    const resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides = [];

    // Find closest snap
    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }
    return guides;
  }

  drawGuides(guides) {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [0, 0, this.stage.width(), 0],
          stroke: '#cccccc',
          strokeWidth: 1,
          name: 'guide-line',
          dash: [4, 6],
        });
        this.devLayer.add(line);
        line.absolutePosition({ x: 0, y: lg.lineGuide });
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, 0, 0, this.stage.height()],
          stroke: '#cccccc',
          strokeWidth: 1,
          name: 'guide-line',
          dash: [4, 6],
        });
        this.devLayer.add(line);
        line.absolutePosition({ x: lg.lineGuide, y: 0 });
      }
    });
  }

  editText(element) {
    if (element.getClassName() !== 'Text') {
      throw Error('Element is not a text');
    }

    if (this.textarea) {
      this.textarea.destroy();
    }

    this.textarea = new CanvasTextarea(
      element,
      // onShow
      () => {
        element.hide();
        const tr = this.layer.find('Transformer').find((tr) => tr.nodes()[0] === element);
        if (tr) tr.nodes([]);
      },
      // onHide
      (content) => {
        element.show();
        if (content) {
          element.text(content);
          this.handleContentChange();
        }
        this.textarea = null;
      },
    ).show();
  }

  resetTransformers() {
    Object.keys(this.transformers).forEach((key) => {
      this.transformers[key].nodes([]);
    });
  }

  /* Events */
  handleContentChange() {
    const content = KonvaSerializer.serialize(this.layer);

    this.content = content;
    this.onChange(content);
  }

  handleClickEvent(e) {
    let { target } = e;

    // Reset currently selected objects
    this.resetSelection();

    // Call callback for left button click
    if (e.evt.button === 0 && this.onLeftClickEvent) {
      this.onLeftClickEvent(target);
    }

    // If click on empty area - do nothing
    if (target === this.stage) {
      return;
    }

    // If click on element in Group - select Group
    if (target.getParent().getType() === 'Group') {
      target = target.getParent();
    }

    // Mark object as selected and show transformer
    if (target.getType() === 'Shape' || target.getType() === 'Group') {
      target.addName('selected');
      this.transformers[target.className || target.getType()].nodes([target]);
    }
  }

  handleContextmenuEvent(e) {
    e.evt.preventDefault();

    if (e.target === this.stage) {
      // Do nothing while click on empty area
      return;
    }

    const selectedElement = e.target;
    this.onContextmenuEvent(selectedElement);
  }

  handleDragMoveEvent(e) {
    // Snap feature (only for elements)
    if (!e.target.hasName('element')) {
      return;
    }

    // TODO: Reset control panel!

    // Clear all previous lines on the screen
    this.devLayer.find('.guide-line').forEach((l) => l.destroy());

    // Find possible snapping lines
    const lineGuideStops = this.getLineGuideStops(e.target);
    // Find snapping points of current object
    const itemBounds = CanvasWrapper.getObjectSnappingEdges(e.target);

    // Find where can we snap current object
    const guides = this.getGuides(lineGuideStops, itemBounds);

    if (!guides.length) {
      return;
    }

    this.drawGuides(guides);

    const absPos = e.target.absolutePosition();
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            default: { // H
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            default: { // H
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            default: { // H
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        default:
          break;
      }
    });
    e.target.absolutePosition(absPos);
  }

  handleDragEndEvent() {
    // Clear all previous guide lines
    this.devLayer.find('.guide-line').forEach((l) => l.destroy());
    this.handleContentChange();
  }

  handleDblClickEvent(e) {
    if (e.target.getClassName() === 'Text') {
      this.editText(e.target);
    }
  }

  static handleElementTransformEvent(e) {
    const node = e.target;

    // Reset scale for text, line and rect elements
    if (['Text', 'Line', 'Rect'].includes(node.getClassName())) {
      if (node.getClassName() === 'Line') {
        const newPoints = node.points().map((p, i) => {
          if (i % 2 === 0) {
            return p * node.scaleX();
          }
          return p * node.scaleY();
        });

        node.setAttrs({
          points: newPoints,
          scaleX: 1,
          scaleY: 1,
        });
        return;
      }

      node.setAttrs({
        width: Math.max(node.width() * node.scaleX(), MIN_WIDTH),
        height: Math.max(node.height() * node.scaleY(), 1),
        scaleX: 1,
        scaleY: 1,
      });
    }
  }

  /* Utils */
  static generateUniqueID() {
    return String((new Date()).getTime());
  }
}
