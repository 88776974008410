import React from 'react';
import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Loader from '../Common/Loader';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import { reducer, sendRequest } from '../utils';

const Connections = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleDeleteConnection = (connectionId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/${connectionId}`, 'DELETE')
      .then((response) => {
        if (response.status_code === 200) {
          const connections = state.data.filter((connection) => connection._id !== connectionId);
          dispatch({ type: 'FETCH_SUCCESS', payload: connections });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message || UNKNOWN_ERROR_MESSAGE });
        }
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_FAILURE', error: err.message || UNKNOWN_ERROR_MESSAGE });
      });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('connections', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  // if (state.isError) {
  //   return (
  //     <Container className="mt-4">
  //       <Row>
  //         <Col md="12">
  //           <Alert variant="danger">
  //             <Alert.Heading className="mb-3">Ooops, something went wrong!</Alert.Heading>
  //             <p>{state.error}</p>
  //             <hr />
  //             <Button as={NavLink} to="/" variant="outline-primary">Go to labels</Button>
  //             <span className="ms-3 me-3 d-inline-block">or</span>
  //             <Button as={NavLink} to="/connections" variant="outline-primary">Go to Connections</Button>
  //           </Alert>
  //         </Col>
  //       </Row>
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="mb-4">
            Connections
            <Button as={NavLink} variant="outline-primary" to="/connections/new" className="mx-3">
              <i className="bi bi-file-earmark" />
              &nbsp;Create New Connection
            </Button>
          </h2>

          {state.isError && (
            <Alert variant="danger">
              <Alert.Heading className="mb-3">Ooops, something went wrong!</Alert.Heading>
              <p>{state.error}</p>
            </Alert>
          )}

          {!state.data.length
            ? (
              <Alert>
                <Alert.Heading className="mb-3">No connections found!</Alert.Heading>
                <p>Click the button above to create a new connection.</p>
              </Alert>
            )
            : (
              <Table striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>URL</th>
                    <th>Database</th>
                    <th>API Key</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.data.map((connection, i) => (
                    <tr key={connection._id}>
                      <td>{i + 1}</td>
                      <td>{connection.name}</td>
                      <td>{connection.url}</td>
                      <td>{connection.database}</td>
                      <td>{connection.api_key}</td>
                      <td>
                        <Button as={NavLink} to={`/connections/${connection._id}`} variant="outline-primary">Edit</Button>
                        <Button onClick={() => handleDeleteConnection(connection._id)} variant="outline-danger ms-2">Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default Connections;
