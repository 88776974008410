import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';

import { logout, sendRequest, useAuth } from './utils';

// Source: https://dev.to/shaan_alam/how-to-create-a-custom-drag-drop-hook-in-react-nme
export function useDragAndDrop() {
  const [dragOver, setDragOver] = useState(false);
  const [fileDropError, setFileDropError] = useState('');

  const onDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = () => setDragOver(false);

  return {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError,
  };
}

// Source: https://usehooks.com/useKeyPress/
export function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', downHandler);
    document.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener('keydown', downHandler);
      document.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
}

export function useUser() {
  const [logged] = useAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Load user infromation from the server
    if (logged) {
      sendRequest('users', 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            setUser(response.data);
          } else {
            logout();

            // Clean local storage
            // This is a bit hacky but it works
            localStorage.clear();
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          setUser(null);
        });
    }
  }, [logged]);

  return user;
}

export function useFontFaceObserver(fontFaces) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const promises = fontFaces.map(
      ({ family, weight, style, stretch }) => new FontFaceObserver(family, { weight, style, stretch }).load(),
    );

    Promise.all(promises)
      .then(() => setLoaded(true))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('An error occurred during font loading');
      });
  }, [fontFaces]);

  return loaded;
}
