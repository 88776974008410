import React from 'react';
import PropTypes from 'prop-types';
import { sendRequest } from '../../../utils';
import { getPreviewFromLabelary } from '../utils';

const ValueChangeWidget = ({ defaultValue, onValueChange, minValue, maxValue, tooltip }) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleValueChange = (change) => {
    const newValue = value + change;
    setValue(newValue);
    onValueChange(newValue);
  };

  return (
    <span className="zld-control-button" data-toggle="tooltip" title={tooltip}>
      <button
        type="button"
        onClick={() => handleValueChange(-1)}
        disabled={value <= minValue}
      >
        {
          value <= minValue
            ? <img src="/images/decrease-inactive.svg" alt="decrease" />
            : <img src="/images/decrease.svg" alt="decrease" />
        }
      </button>
      <span style={{ width: '30px', display: 'inline-block', textAlign: 'center' }}>{value}</span>
      <button
        type="button"
        onClick={() => handleValueChange(1)}
        disabled={value >= maxValue}
      >
        {
          value >= maxValue
            ? <img src="/images/increase-inactive.svg" alt="increase" />
            : <img src="/images/increase.svg" alt="increase" />
        }
      </button>
    </span>
  );
};

ValueChangeWidget.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  tooltip: PropTypes.string.isRequired,
};

const BarcodeSizeWidget = ({ element }) => (
  <ValueChangeWidget
    defaultValue={element.attrs.barcodeSize || 3}
    onValueChange={(value) => {
      element.setAttr('barcodeSize', value);
      element.getLayer().fire('zld:change');
    }}
    minValue={1}
    maxValue={10}
    tooltip="Change barcode size"
  />
);

BarcodeSizeWidget.propTypes = {
  element: PropTypes.object.isRequired,
};

const FontSizeWidget = ({ element }) => (
  <ValueChangeWidget
    defaultValue={element.attrs.fontSize || 20}
    onValueChange={(value) => {
      element.fontSize(value);
      element.getLayer().fire('zld:change');
    }}
    minValue={1}
    maxValue={500}
    tooltip="Change font size"
  />
);

FontSizeWidget.propTypes = {
  element: PropTypes.object.isRequired,
};

const FONTS = ['Roboto Condensed', 'Roboto Mono'];
const OBJECT_CONTROLS = {
  Text: [
    {
      name: 'Barcode',
      controls: [
        {
          name: 'barcode',
          tooltip: 'Render as barcode',
          image: '/images/upc.svg',
          imageInactive: '/images/upc-inactive.svg',
          clickHandler: (element) => {
            element.setAttr('isBarcode', !element.getAttr('isBarcode'));
            element.setAttr('isQR', false);
          },
          toggle: true,
          isActive: (element) => element.getAttr('isBarcode'),
        },
        {
          name: 'qr',
          tooltip: 'Render as QR',
          image: '/images/qr-code.svg',
          imageInactive: '/images/qr-code-inactive.svg',
          clickHandler: (element) => {
            element.setAttr('isQR', !element.getAttr('isQR'));
            element.setAttr('isBarcode', false);
          },
          toggle: true,
          isActive: (element) => element.getAttr('isQR'),
        },
        {
          name: 'size',
          tooltip: 'Barcode / QR size',
          toggle: false,
          isActive: () => true,
          isVisible: (element) => element.getAttr('isQR') || element.getAttr('isBarcode'),
          widget: BarcodeSizeWidget,
        },
      ],
    },
    {
      name: 'Alignment',
      controls: [
        {
          name: 'left',
          tooltip: 'Align left',
          image: '/images/text-left.svg',
          imageInactive: '/images/text-left-inactive.svg',
          clickHandler: (element) => {
            element.align('left');
          },
          toggle: true,
          isActive: (element) => element.align() === 'left',
        },
        {
          name: 'center',
          tooltip: 'Align center',
          image: '/images/text-center.svg',
          imageInactive: '/images/text-center-inactive.svg',
          clickHandler: (element) => {
            element.align('center');
          },
          toggle: true,
          isActive: (element) => element.align() === 'center',
        },
        {
          name: 'right',
          tooltip: 'Align right',
          image: '/images/text-right.svg',
          imageInactive: '/images/text-right-inactive.svg',
          clickHandler: (element) => {
            element.align('right');
          },
          toggle: true,
          isActive: (element) => element.align() === 'right',
        },
      ],
    },
    {
      name: 'Font',
      controls: [
        {
          name: 'font-family',
          tooltip: 'Change font',
          image: '/images/font-family.svg',
          imageInactive: '/images/font-family.svg',
          clickHandler: (element) => {
            const currentFont = element.fontFamily();

            // Find next font
            const currentFontIndex = FONTS.indexOf(currentFont);
            const newFontIndex = currentFontIndex < FONTS.length - 1 ? currentFontIndex + 1 : 0;
            const newFont = FONTS[newFontIndex];

            element.fontFamily(newFont);
          },
          toggle: false,
          isActive: () => true,
        },
        {
          name: 'font-size',
          tooltip: 'Change font size',
          toggle: false,
          isActive: () => true,
          widget: FontSizeWidget,
        },
      ],
    },
  ],
  Rect: [
    {
      name: 'Fill',
      controls: [
        {
          name: 'fill',
          tooltip: 'Control fill of the rectangle',
          image: '/images/square-fill.svg',
          imageInactive: '/images/square-fill-inactive.svg',
          clickHandler: (element) => {
            element.fill(!element.fill() ? 'black' : undefined);
          },
          toggle: true,
          isActive: (element) => element.fill(),
        },
      ],
    },
    {
      name: 'Stroke',
      controls: [
        {
          name: 'increase',
          tooltip: 'Increase stroke width',
          image: '/images/plus-square.svg',
          imageInactive: '/images/plus-square-inactive.svg',
          clickHandler: (element) => {
            element.strokeWidth(element.strokeWidth() + 1);
          },
          toggle: false,
          isActive: (element) => {
            const width = element.width() * element.scaleX();
            const height = element.height() * element.scaleY();

            return width > element.strokeWidth() && height > element.strokeWidth();
          },
        },
        {
          name: 'decrease',
          tooltip: 'Decrease stroke width',
          image: '/images/dash-square.svg',
          imageInactive: '/images/dash-square-inactive.svg',
          clickHandler: (element) => {
            element.strokeWidth(element.strokeWidth() - 1);
          },
          toggle: false,
          isActive: (element) => element.strokeWidth() > 1,
        },
      ],
    },
  ],
  Line: [
    {
      name: 'Stroke',
      controls: [
        {
          name: 'increase',
          tooltip: 'Increase stroke width',
          image: '/images/plus-square.svg',
          imageInactive: '/images/plus-square-inactive.svg',
          clickHandler: (element) => {
            element.strokeWidth(element.strokeWidth() + 1);
          },
          toggle: false,
          isActive: () => true,
        },
        {
          name: 'decrease',
          tooltip: 'Decrease stroke width',
          image: '/images/dash-square.svg',
          imageInactive: '/images/dash-square-inactive.svg',
          clickHandler: (element) => {
            element.strokeWidth(element.strokeWidth() - 1);
          },
          toggle: false,
          isActive: (element) => element.strokeWidth() > 1,
        },
      ],
    },
  ],
  Group: [
    {
      name: 'Orientation',
      controls: [
        {
          name: 'horizontal',
          tooltip: 'Render horizontally',
          image: '/images/stack-horizontally.svg',
          imageInactive: '/images/stack-horizontally-inactive.svg',
          clickHandler: (element) => {
            element.setAttr('orientation', 'horizontal');
          },
          toggle: true,
          isActive: (element) => element.getAttr('orientation') === 'horizontal',
        },
        {
          name: 'vertical',
          tooltip: 'Render vertically',
          image: '/images/stack-vertically.svg',
          imageInactive: '/images/stack-vertically-inactive.svg',
          clickHandler: (element) => {
            element.setAttr('orientation', 'vertical');
          },
          toggle: true,
          isActive: (element) => element.getAttr('orientation') === 'vertical',
        },
      ],
    },
    {
      name: 'Actions',
      controls: [
        {
          name: 'update',
          tooltip: 'Update nested label preview',
          image: '/images/refresh.svg',
          imageInactive: '/images/plus-square-inactive.svg',
          clickHandler: (element) => {
            const labelId = element.attrs.data.label_id;

            sendRequest(`labels/${labelId}`, 'GET')
              .then((response) => {
                if (response.status_code === 200) {
                  const label = response.data;

                  const onImageLoad = (base64) => {
                    const newWidth = Math.round(label.width * label.dpi);
                    const newHeight = Math.round(label.height * label.dpi);

                    const nestedImage = element.findOne('Image');
                    if (nestedImage) {
                      nestedImage.destroy();
                    }

                    window.Konva.Image.fromURL(base64, (image) => {
                      image.setAttrs({
                        url: base64,
                        x: 0,
                        y: 0,
                        width: newWidth,
                        height: newHeight,
                        draggable: false,
                      });
                      element.add(image);
                      element.getStage().batchDraw();
                    });

                    // Update rect size
                    const rect = element.findOne('Rect');
                    rect.width(label.width * label.dpi);
                    rect.height(label.height * label.dpi);

                    // Update group size
                    element.width(label.width * label.dpi);
                    element.height(label.height * label.dpi);

                    // Manually fire change event
                    element.getLayer().fire('zld:change');
                  };
                  const onImageError = (error) => {
                    alert(error);
                  };

                  getPreviewFromLabelary(
                    label.zpl, label.dpi, label.width, label.height,
                    onImageLoad, onImageError,
                  );
                } else {
                  // eslint-disable-next-line no-console
                  console.log(response);
                  alert(response.message);
                }
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                alert('Error loading label preview. Please try again later.');
              });
          },
          toggle: false,
          isActive: () => true,
        },
        {
          name: 'open',
          tooltip: 'Open nested label',
          image: '/images/box-arrow-up-right.svg',
          imageInactive: '/images/box-arrow-up-right-inactive.svg',
          clickHandler: (element) => {
            const labelId = element.attrs.data.label_id;
            window.open(`/${labelId}`, '_blank');
          },
          toggle: false,
          isActive: () => true,
        },
      ],
    },
  ],
};

export {
  OBJECT_CONTROLS,
  FONTS,
};
