import React from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Loader from '../../Common/Loader';
import { reducer, sendRequest } from '../../utils';
import { UNKNOWN_ERROR_MESSAGE } from '../../constants';

const CustomFieldsSelector = ({ connectionId, model, onAddCustomField }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );
  const [selectedFields, setSelectedFields] = React.useState([]);

  const handleCustomFieldChange = (value, index) => {
    const field = state.data[index].fields.find((field) => field.name === value);

    const newState = state.data.filter((item) => item.level <= index);

    if (field && field.type === 'many2one') {
      // Fetch related model fields and append to new select element
      // TODO: Refactor, there are two similar requests
      sendRequest(`connections/${connectionId}/fields/${field.comodel}`, 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            const fields = response.data;
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: [...newState, { fields, level: newState.length }],
            });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    } else {
      dispatch({
        type: 'FETCH_SUCCESS',
        payload: newState,
      });
    }

    // Update selected fields
    const newSelectedFields = selectedFields.filter((item) => item.level < index);
    newSelectedFields.push({ name: value, level: newSelectedFields.length });
    setSelectedFields(newSelectedFields);
  };

  const handleAddCustomField = () => {
    onAddCustomField(selectedFields.map((item) => item.name).join('.'));
  };

  React.useEffect(() => {
    sendRequest(`connections/${connectionId}/fields/${model}`, 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          const fields = response.data;
          dispatch({ type: 'FETCH_SUCCESS', payload: [{ fields, level: 0 }] });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, [connectionId, model]);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <Col md="12">
      {state.isError ? (
        <Form.Control as="select" disabled>
          <option>{state.error}</option>
        </Form.Control>
      ) : (
        <InputGroup className="mb-3">
          <InputGroup.Text>Field:</InputGroup.Text>
          {
            state.data.map((fields, i) => (
              <Form.Select key={fields.level} id="model" name="model" onChange={(e) => handleCustomFieldChange(e.target.value, i)}>
                <option>Select label model</option>
                {fields.fields.map((field) => (
                  <option key={field.name} value={field.name}>
                    {`${field.label}${field.type === 'many2one' ? ' →' : ''}`}
                  </option>
                ))}
              </Form.Select>
            ))
          }

          <Button variant="primary" type="submit" onClick={handleAddCustomField}>Add Custom Field</Button>
        </InputGroup>
      )}
    </Col>
  );
};

CustomFieldsSelector.propTypes = {
  connectionId: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  onAddCustomField: PropTypes.func.isRequired,
};

export default CustomFieldsSelector;
