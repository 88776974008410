import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { SERVER_DOWN_ERROR } from '../constants';
import { reducer, sendRequest, useAuth } from '../utils';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const ForgotPassword = () => {
  const [logged] = useAuth();
  const recaptchaRef = React.createRef();

  const [email, setEmail] = React.useState('');
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  if (logged) {
    return <Navigate to="/" />;
  }

  const onSubmitClick = (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    // Get the recaptcha token
    const token = recaptchaRef.current.getValue();
    if (!token) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please verify you are not a robot.' });
      return;
    }

    if (!email) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Email is required' });
      return;
    }

    const opts = {
      email,
      recaptcha_token: token,
    };

    sendRequest('auth/password/forgot', 'POST', opts)
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: SERVER_DOWN_ERROR });
      });
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h2 className="mb-4 text-orange">Restore Password</h2>
          {
            state.isError
              ? <Alert variant="danger">{state.error}</Alert>
              : ''
          }

          {
            state.message
              ? <Alert variant="success">{state.message}</Alert>
              : ''
          }

          <Form className="mb-4">
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Button variant="primary" type="submit" onClick={onSubmitClick}>Confirm</Button>

              {state.isLoading && <Spinner animation="border" />}
            </div>
          </Form>

        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
