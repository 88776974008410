import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonGroup, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Loader from '../Common/Loader';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import { reducer, sendRequest } from '../utils';

const groupLabelsByConnection = (labels) => labels.reduce((acc, label) => {
  const key = label.connection.name;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(label);
  return acc;
}, {});

const ConnectionLabelsTableView = ({ labels }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: labels, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleDeleteLabelClick = (labelId) => {
    if (window.confirm('Are you sure you want to delete this label?')) {
      sendRequest(`labels/${labelId}`, 'DELETE')
        .then((response) => {
          if (response.status_code === 200) {
            // Remove label from state
            const labels = state.data.filter((label) => label._id !== labelId);
            dispatch({
              type: 'FETCH_SUCCESS',
              message: 'Label deleted successfully',
              payload: labels,
            });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        }).catch(() => {
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  };

  return (
    <>
      {
        state.isError && (
          <Alert variant="danger">
            {state.error}
          </Alert>
        )
      }
      <Table bordered hover size="sm" className="mt-1">
        <thead>
          <tr className="text-center">
            <th className="w-35">Name</th>
            <th className="w-10">Model</th>
            <th className="w-15">Size</th>
            <th className="w-40">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
          state.data.map((label) => (
            <tr key={label._id}>
              <td>{label.name}</td>
              <td className="text-center">{label.model.label}</td>
              <td className="text-center">{`${label.width} x ${label.height} inches (DPI: ${label.dpi})`}</td>
              <td className="text-center">
                <ButtonGroup>
                  <Button as={NavLink} variant="primary" to={`/${label._id}`} size="sm">Edit Label</Button>

                  {
                    label.odoo_label_url && (
                      <Button
                        variant="outline-primary"
                        href={label.odoo_label_url}
                        target="_blank"
                        rel="noreferrer"
                        size="sm"
                      >
                        Open in Odoo
                      </Button>
                    )
                  }

                  <Button variant="outline-danger" onClick={() => handleDeleteLabelClick(label._id)} size="sm">Delete Label</Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </>
  );
};

ConnectionLabelsTableView.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    model: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    dpi: PropTypes.number.isRequired,
    odoo_label_url: PropTypes.string,
  })).isRequired,
};

const ConnectionLabelsCardView = ({ labels }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: labels, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleDeleteLabelClick = (labelId) => {
    if (window.confirm('Are you sure you want to delete this label?')) {
      sendRequest(`labels/${labelId}`, 'DELETE')
        .then((response) => {
          if (response.status_code === 200) {
            // Remove label from state
            const labels = state.data.filter((label) => label._id !== labelId);
            dispatch({
              type: 'FETCH_SUCCESS',
              message: 'Label deleted successfully',
              payload: labels,
            });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
          }
        }).catch(() => {
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        });
    }
  };

  return (
    <>
      {
        state.isError && (
          <Alert variant="danger">
            {state.error}
          </Alert>
        )
      }
      <Row xs={1} md={2} lg={3}>
        {
          labels.map(
            (label) => (
              <Col key={label._id} className="mb-3">
                <Card>
                  <Card.Body>
                    <Card.Title>{label.name}</Card.Title>
                    <Card.Text>
                      {`Model: ${label.model.label}`}
                      <br />
                      {`Size: ${label.width} x ${label.height} inches (DPI: ${label.dpi})`}
                    </Card.Text>
                    <ButtonGroup>
                      <Button as={NavLink} variant="primary" to={`/${label._id}`}>Edit Label</Button>

                      {
                        label.odoo_label_url && (
                          <Button
                            variant="outline-primary"
                            href={label.odoo_label_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open in Odoo
                          </Button>
                        )
                      }

                      <Button variant="outline-danger" onClick={() => handleDeleteLabelClick(label._id)}>Delete Label</Button>
                    </ButtonGroup>
                  </Card.Body>
                </Card>
              </Col>
            ),
          )
        }
      </Row>
    </>
  );
};

ConnectionLabelsCardView.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    model: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    dpi: PropTypes.number.isRequired,
    odoo_label_url: PropTypes.string,
  })).isRequired,
};

const LabelsList = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );
  const [view, setView] = React.useState('list');

  const handleFetchLabels = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('labels', 'GET')
      .then((response) => {
        if (response.status_code !== 200) {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          const labels = response.data;
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: labels,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  React.useEffect(() => {
    handleFetchLabels();
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Container className="mt-4">
        <h2 className="mb-4">
          Labels
          <Button as={NavLink} variant="outline-primary" to="/new" className="mx-3">
            <i className="bi bi-file-earmark" />
            &nbsp;Create New Label
          </Button>

          {/* Show as list or cards */}
          <ButtonGroup className="mx-2">
            <Button variant={view === 'list' ? 'primary' : 'outline-primary'} onClick={() => setView('list')}>
              <i className="bi bi-list" />
            </Button>
            <Button variant={view === 'cards' ? 'primary' : 'outline-primary'} onClick={() => setView('cards')}>
              <i className="bi bi-card-list" />
            </Button>
          </ButtonGroup>
        </h2>

        {
          state.isError && (
            <Alert variant="danger">
              {state.error}
            </Alert>
          )
        }

        {
          !Object.keys(state.data).length && !state.isError && (
            <Row>
              <Col md="12">
                <Alert variant="primary">
                  <Alert.Heading>Ooops, no labels found!</Alert.Heading>
                  <p>
                    To create your first label, please click on the &quot;Create New Label&quot; button above.
                  </p>
                </Alert>
              </Col>
            </Row>
          )
        }

        {
          Object.entries(groupLabelsByConnection(state.data)).map(([key, labels]) => (
            <Row className="g-4 mb-4" key={key}>
              <Col xs={12} md={12} lg={12}>
                <h4>{`Odoo Connection: ${key}`}</h4>
              </Col>

              <Col xs={12} md={12} lg={12} className="mt-1">
                {
                view === 'list' && labels && (
                  <ConnectionLabelsTableView labels={labels} />
                )
              }

                {
                view === 'cards' && (
                  <ConnectionLabelsCardView labels={labels} />
                )
              }
              </Col>
            </Row>
          ))
        }
      </Container>
    </div>
  );
};

export default LabelsList;
