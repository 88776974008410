import React from 'react';
import { Alert, Button, Container, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from '../../Common/Loader';
import { UNKNOWN_ERROR_MESSAGE } from '../../constants';
import { reducer, sendRequest } from '../../utils';

const ConnectionSelector = ({ onChange }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );
  const [selectedValue, setSelectionValue] = React.useState(null);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('connections', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Container>
        <h2 className="mb-4">Create Label</h2>
        <h4 className="mb-4">Select Odoo connection to use for a new label</h4>

        {
          !state.data.length ? (
            <Alert variant="warning">
              <Alert.Heading>
                No connections found
              </Alert.Heading>
              <p>
                Please, create a new connection to use for a new label.
              </p>
              <Button as={NavLink} to="/connections/new" variant="outline-primary" className="ml-2">Create connection</Button>
            </Alert>
          )
            : (
              <>
                <Table bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>URL</th>
                      <th>Database</th>
                      <th>API Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.data.map((connection, i) => (
                      <tr
                        key={connection._id}
                        onClick={() => setSelectionValue(connection)}
                        className={selectedValue && connection._id === selectedValue._id ? 'bg-primary' : ''}
                      >
                        <td>{i + 1}</td>
                        <td>{connection.name}</td>
                        <td>{connection.url}</td>
                        <td>{connection.database}</td>
                        <td>{connection.api_key}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button onClick={() => onChange(selectedValue)} disabled={!selectedValue} variant="outline-primary">Select</Button>
              </>
            )
        }
      </Container>
    </div>
  );
};

ConnectionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ConnectionSelector;
