import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { sendRequest } from '../../utils';

const ConnectionStatus = ({ connectionId }) => {
  const [status, setStatus] = React.useState(false);

  React.useEffect(() => {
    if (connectionId) {
      sendRequest(`connections/${connectionId}/status`, 'GET')
        .then((response) => {
          if (response.status_code === 200) {
            setStatus(response.data.is_valid);
          } else {
            setStatus(false);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setStatus(false);
        });
    }
  }, [connectionId]);

  return (
    <>
      {
        status
          ? (
            <Button variant="outline-success" disabled>Successfully connected to Odoo</Button>
          )
          : (
            <Button as={NavLink} to={`/connections/${connectionId}`} variant="outline-danger" target="_blank">
              Can&apos;t connect to Odoo. Please check your credentials
            </Button>
          )
      }
    </>
  );
};

ConnectionStatus.propTypes = {
  connectionId: PropTypes.string,
};

ConnectionStatus.defaultProps = {
  connectionId: '',
};

export default ConnectionStatus;
