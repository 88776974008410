import React from 'react';
import { Navigate, NavLink, useParams } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { login, reducer, sendRequest, useAuth } from '../utils';
import Loader from '../Common/Loader';
import { SERVER_DOWN_ERROR } from '../constants';

const ConfirmAccount = () => {
  const [logged] = useAuth();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const { token } = useParams();

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });
    sendRequest('auth/confirmation', 'POST', { token })
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
          login(response.data);
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: SERVER_DOWN_ERROR });
      });
  }, [token]);

  if (logged) {
    return <Navigate to="/" />;
  }

  return (
    <Row className="row justify-content-md-center">
      <Col md="6">
        <h2 className="mb-4 text-orange">Confirmation</h2>
        {
          state.isLoading
            ? <Loader />
            : (
              <>
                {
                  state.isError
                    ? (
                      <Alert variant="danger">
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>{state.error}</p>
                        <p>
                          <Button as={NavLink} variant="outline-primary" to="/resend-confirmation">Resend Confirmation Email</Button>
                        </p>
                      </Alert>
                    )
                    : (
                      <>
                        <h5 className="mb-4 text-orange">Your account successfully confirmed!</h5>
                        <Button as={NavLink} variant="warning" to="/login">Login</Button>
                      </>
                    )
                }
              </>
            )
        }
      </Col>
    </Row>
  );
};

export default ConfirmAccount;
