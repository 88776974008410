import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { reducer, sendRequest } from '../../utils';
import Loader from '../../Common/Loader';
import { UNKNOWN_ERROR_MESSAGE } from '../../constants';

const ModelSelect = ({ connectionId, onChange, value, disabled }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleChange = (e) => {
    const select = e.target;
    const selectedOption = select.options[select.selectedIndex];
    onChange({ value: selectedOption.value, label: selectedOption.textContent });
  };

  const fetchModels = () => {
    dispatch({ type: 'FETCH_INIT' });
    sendRequest(`connections/${connectionId}/models`, 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        dispatch({ type: 'FETCH_FAILURE', error: UNKNOWN_ERROR_MESSAGE });
      });
  };

  React.useEffect(() => {
    fetchModels();
  }, []);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Form.Label>Model</Form.Label>
      {
        state.isError ? (
          <Form.Control as="select" disabled>
            <option>Connection error. Please check Odoo credentials</option>
          </Form.Control>
        ) : (
          <Form.Select
            id="model"
            name="model"
            onChange={handleChange}
            value={value}
            disabled={disabled}
          >
            <option value="">Select label model</option>
            {state.data.map((model) => (
              <option value={model.model} key={model.id}>{model.name}</option>
            ))}
          </Form.Select>
        )
      }
    </>
  );
};

ModelSelect.propTypes = {
  connectionId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

ModelSelect.defaultProps = {
  value: '',
  disabled: false,
};

export default ModelSelect;
